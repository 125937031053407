import "./login.css";
import { logo, logoreg, logosmall } from "../../../assets/images";
import HeaderScreen from "../../_common/Header/HeaderScreen";
import useLoginDetails from "./useLoginDetails";
import FooterScreen from "../../_common/FooterScreen/FooterScreen";
import { withTranslation } from "react-i18next";
const Login = (props: any) => {
  /**
   * localisation implementation
   */
  const { t } = props;
  const {
    mobile_no,
    password,
    submitted,
    handleChange,
    handleSubmit,
    forgotPassword,
    registerRedirect
  } = useLoginDetails(t);


  /**
   * 
   * @returns render header screen
   */
  const renderHeader = () => {
    return (
      <div className="main_login flt">
        <HeaderScreen />
      </div>
    );
  };

  /**
   * 
   * @returns render footer screen
   */
  const renderFooter = () => {
    return (
      <div className="main_login flt">
        <FooterScreen />
      </div>
    )
  }
  /**
   * 
   * @returns render main login screen
   */
  const mainLogin = () => {
    return (
      <div className="login_main flt">
        <div className="login_form">
          <div className="row">
            <div className="col-sm-10">
              <div className="main_block">
                <div className="welcome flt">
                  {t("welcome")}
                </div>
                <div className="mob_number flt">
                  <label>{t("Mobile number")}</label>
                  <input
                    type="number"
                    placeholder={t("mobile_placeholder")}
                    name="mobile_no"
                    value={mobile_no}
                    onChange={handleChange}
                    onWheelCapture={(e: any) => {
                      e.target.blur();
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSubmit(e);
                      }
                    }}
                    className={submitted && !mobile_no ? " is-invalid" : ""}
                  />
                </div>
                {submitted && !mobile_no && (
                  <div className="invalid-feedback invalid-mob">
                    {t("mobile_required")}
                  </div>
                )}
                <div className="mob_number flt">
                  <label>{t("password")}</label>
                  <input
                    type="password"
                    placeholder={t("password_placeholder")}
                    id="password"
                    name="password"
                    value={password}
                    onChange={handleChange}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSubmit(e);
                      }
                    }}
                    className={
                      submitted && !password ? " is-invalid" : "login_lbl"
                    }
                  />
                </div>
                {submitted && !password && (
                  <div className="invalid-feedback invalid-mob">
                    {t("password_required")}
                  </div>
                )}

                <div className="forgot_password">
                  {t("forgot_password")} <span onClick={forgotPassword}>{t("click_here")}</span>

                </div>
                <div className="login_btn flt">
                  <button className="login" onClick={handleSubmit}>{t("login")}</button>
                </div>
                <div className="forgot_password_reg flt">
                  {t("dont_have_account")} <span onClick={registerRedirect}>{t("register")}</span>

                </div>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="image_sub flt">
                <img src={logosmall} alt="pop_background" />
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
  return (
    <>
      {renderHeader()}
      {mainLogin()}
      {renderFooter()}
    </>
  );
}

export default withTranslation()(Login);