import { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { alertActions } from "../../../actions";
import { userLogout } from "../../../actions/UserAction";
import { ProductService } from "../../../services";

const useMenu = () => {
  let history = useNavigate();
  const navigate = useNavigate();
  const user_id: string = useSelector(
    (state: RootStateOrAny) => state?.userData?.token
  );
  const menudata: any = useSelector(
    (state: RootStateOrAny) => state?.menuData?.menu
  );
  const [cartCount, setCartCount] = useState<any>("");
  // Search Bar popup
  const [showSearchBar, setShowSearchBar] = useState(false);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (user_id) {
      ProductService.getCart().then((response) => {
        if (response?.data?.status?.code === 0) {
          setLoader(true);
          setCartCount(response?.data?.data?.cart_count);
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  const privacy_notice = () => {
    setLoader(true);
    navigate("/privacypolicy");
  };
  const terms_condition = () => {
    setLoader(true);
    navigate("/terms-conditions");
  };
  const cancellation_refund = () => {
    setLoader(true);
    navigate("/cancellation-refund");
  };
  const login_screen = () => {
    setLoader(true);
    navigate("/Login");
  };
  const handleLogout = () => {
    dispatch(userLogout());
    setLoader(true);
    navigate("/Login");
    dispatch(alertActions.success("Logout Success"));
  };
  const getMenuId = (data: string) => {
    window.scrollTo(0, 0);
    setLoader(true);
    navigate(`/product-list/${data}`, {
      state: { name: data },
    });
  };
  //go to home
  const gotoHome = () => {
    setLoader(true);
    navigate("/");
  };
  //move to cart if auth
  const moveCart = () => {
    if (user_id) {
      setLoader(true);
      navigate("/cart");
    } else {
      navigate("/Login");
    }
  };
  //move to wishlist if auth
  const moveWishlist = () => {
    if (user_id) {
      setLoader(true);
      navigate("/wishlist");
    } else {
      navigate("/Login");
    }
  };
  //redirect to about us
  const aboutUs = () => {
    setLoader(true);
    navigate("/about");
  };
  const gold = () => {
    window.scrollTo(0, 0);
    setLoader(true);
    navigate("/product-list/Gold");
  };
  const silver = () => {
    window.scrollTo(0, 0);
    setLoader(true);
    navigate("/product-list/Silver");
  };
  const diamond = () => {
    window.scrollTo(0, 0);
    setLoader(true);
    navigate("/product-list/Diamond");
  };
  const home = () => {
    setLoader(true);
    navigate("/home");
  };
  const planofpurchase = () => {
    setLoader(true);
    navigate("/planofpurchase");
  };
  const openSearch = () => {
    console.log("gfshdsg");
    document.body.classList.add("modal-open");
    setShowSearchBar(true);
  };
  const closeSearch = () => {
    document.body.classList.remove("modal-open");
    setShowSearchBar(false);
  };
  //redirect to contact
  const contactUs = () => {
    setLoader(true);
    navigate("/contact");
  };
  //redirect ro my account
  const myAccount = () => {
    if (user_id) {
      setLoader(true);
      navigate("/myAccount");
    } else {
      navigate("/Login");
    }
  };
  /**
   * get my order details
   */
  const myOrder = () => {
    /**
     * if user exist then only move to my order otherwise move to login screen
     */
    if (user_id) {
      setLoader(true);
      navigate("/myorders");
    } else {
      navigate("/Login");
    }
  };
  const handleLoginForNonUser = () => {
    history("/login");
    // setTimeout(() => {
    //   dispatch(alertActions.error("Please Login..."));
    // }, 50);
  };
  return {
    handleLoginForNonUser,
    login_screen,
    handleLogout,
    user_id,
    menudata,
    getMenuId,
    gotoHome,
    moveCart,
    moveWishlist,
    cartCount,
    gold,
    silver,
    diamond,
    aboutUs,
    planofpurchase,
    home,
    contactUs,
    privacy_notice,
    terms_condition,
    cancellation_refund,
    myAccount,
    openSearch,
    closeSearch,
    myOrder,
    showSearchBar,
    loader,
  };
};
export default useMenu;
