import "./headerScreen.css";
import Menu from "../Menu/Menu";
import { withTranslation } from "react-i18next";
import React from "react";
import Marquee from "react-fast-marquee";
import { time } from "../../../assets/images";
import { phone } from "../../../assets/images";
import { mail } from "../../../assets/images";

const HeaderScreen = (props: any) => {
  /**
   * localisation implementations
   */
  const { t, count } = props;

  /**
   *
   * @returns render marque tag as per design
   */
  const renderHeader = () => {
    return (
      <div className="header_back  flt">
        <div className="header_menu flt">
          <ul className="middlebar flt hide_mobile">
            <li>
              <span>
                <img src={time} alt="" /> Store timings:
              </span>
              {t("ofc_hrs")}
            </li>
            <li> {t("cust_care")}</li>
          </ul>
          <ul className="middlebar middlebar1 flt hide_mobile">
            <li>
              <span>
                <img src={phone} alt="" />
              </span>
              {t("Phone_Number")}
            </li>
            <li>
              <span>
                <img src={mail} alt="" />
              </span>
              {t("mail_us")}
            </li>
          </ul>
        </div>
        <div className="hide_desktop flt">
          <Marquee className="middlebar flt">
            <li>
              {t("Phone_Number")}{" "}
              <a href="tel:08242425977" rel="noreferrer" target="_blank">
                {t("cont_no")}
              </a>
            </li>
            <li>{t("ofc_hrs")}</li>
            <li> {t("cust_care")}</li>
            <li>{t("time")}</li>
            <li className="filler"></li>
            <li>{t("mail_us")}</li>
          </Marquee>
        </div>
      </div>
    );
  };
  /**
   *
   * @returns render menu
   */
  const renderMenu = () => {
    return <Menu count={count} />;
  };

  return (
    <div className="menu_header flt">
      {renderHeader()}
      {renderMenu()}
    </div>
  );
};
export default withTranslation()(HeaderScreen);
