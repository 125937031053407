import { RootStateOrAny, useSelector } from "react-redux";
import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router";
import useCountHook from "../../hooks/useCountHook";

const useHome = () => {
  const [loader, setLoader] = useState(false);
  const menudata = useSelector(
    (state: RootStateOrAny) => state?.menuData?.menu
  );
  const image_url = process.env.REACT_APP_MEDIA_DOMAIN;
  const { wishListCount } = useCountHook();
  const navigate = useNavigate();
  /**
   * getting whats new value
   */
  const getNewId = (data: any) => {
    window.scrollTo(0, 0);
    setLoader(true);
    navigate(`/product-list/${data}`, {
      state: { newName: data },
    });
    console.log(data);
  };
  const getMenuId = (data: string) => {
    window.scrollTo(0, 0);
    setLoader(true);
    navigate(`/product-list/${data}`, {
      state: { name: data },
    });
  };
  //from gold section to productlist
  const getGoldDetails = (data: any, subdata: any) => {
    window.scrollTo(0, 0);
    setLoader(true);
    navigate(`/product-list/${data + " " + subdata}`, {
      state: { name: data, subdata: subdata },
    });
  };

  return {
    loader,
    menudata,
    image_url,
    wishListCount,
    getNewId,
    getMenuId,
    getGoldDetails,
  };
};
export default useHome;
