import { useEffect } from "react";
import { OrderService } from "../../../services/OrderService";
import sample from "../../../assets/images/default.png";
import { ProductService } from "../../../services/ProductService";
import { alertActions } from "../../../actions/alert.actions";
import close_icon from "../../../assets/images/close-pop.png";
import empty_wishlist from "../../../assets/images/empty-wishlist.png";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useCountHook from "../../hooks/useCountHook";

const useWishlistHooks = () => {
  const dispatch = useDispatch();
  let history = useNavigate();
  const [count, setCount] = useState();
  const navigate = useNavigate();
  const [prodList, setProdList] = useState();
  const user_id = useSelector((state) => state?.userData?.user?._id);
  const [lists, setList] = useState([]);
  const [loading, setLoading] = useState("loading");
  const { wishListCount, getWishListCount } = useCountHook();

  const displayProduct = (display) => {
    // const prodNameData = display?.product?.name.replace(/\s+/g, "-");
    // const baseProductName = display?.product?.name.split(" ")[0];
    navigate(`/product-details/${display.product.SKU}`, {
      state: { id: display.product.SKU, userid: user_id },
    });
    setProdValue(display);
  };
  const [prodValue, setProdValue] = useState();

  const cartPage = (product) => {
    imgChange(product.product_id);
    if (
      product.product.pro_details.is_chain === 0 ||
      product.product.pro_details.include_backchain !== "NO"
    ) {
      ProductService.addToCart(
        product.product_id,
        product.product.pro_details.is_chain,
        "",
        ""
      )
        .then((product_info) => {
          if (product_info.data.status.code === 0) {
            dispatch(alertActions.success(product_info.data.status.message));
          }
        })
        .catch((error) => {
          console.log("Errorreg", error);
          //   setLoading(false);
        });
      history("/cart");
    } else {
      setTimeout(() => {
        dispatch(alertActions.success("Please select the back chain"));
      }, 1000);
      navigate(
        `/product-details/${product.product.pro_details.metal.toLowerCase()}/${product.product.name
          .replace(/ /g, "-")
          .toLowerCase()}/${product.product.SKU}`,
        {
          state: { id: product.product.SKU, userid: user_id },
        }
      );
    }
  };
  const imgChange = (product_id) => {
    if (user_id) {
      ProductService.getProdDetails(product_id, user_id)
        .then((prodList) => {
          setProdList(prodList);
          wishListCountss();
          getWishListCount();
          if (prodList.status.code === 4) {
            dispatch(alertActions.error(prodList?.status?.message));
            history("/wishlist");
          }
        })
        .catch((error) => {
          console.log("Errorreg", error);
          //   setLoading(false);
        });
    }
  };

  const wishListCountss = () => {
    if (user_id) {
      OrderService.getWishlist(user_id)
        .then((order) => {
          setCount(order?.data?.data?.total);
          return true;
        })
        .catch((error) => {
          console.log("Errorreg", error);
          return false;
        });
    }
  };

  useEffect(() => {
    if (user_id) {
      OrderService.getWishlist(user_id)
        .then((order) => {
          setList(order.data.data.items);
          setLoading("notempty");
          return true;
        })
        .catch((error) => {
          setLoading("empty");
          return false;
        });
    }
  }, [count]);

  return {
    close_icon,
    lists,
    displayProduct,
    history,
    prodValue,
    sample,
    cartPage,
    imgChange,
    count,
    empty_wishlist,
    wishListCount,
    loading,
    prodList,
  };
};
export default useWishlistHooks;
