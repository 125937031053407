import FooterScreen from "../../_common/FooterScreen/FooterScreen";
import HeaderScreen from "../../_common/Header/HeaderScreen";
import "./PrivacyPolicy.css";
import usePrivacyPolicy from "./usePrivacyPolicy";
import { withTranslation } from "react-i18next";
import { loader } from "../../../assets/images";
import useCountHook from "../../hooks/useCountHook";

const PrivacyPolicy = (props: any) => {
  const { wishListCount } = useCountHook();
  const { t } = props;
  const { privacyData, loaderdata } = usePrivacyPolicy();
  console.log(privacyData?.data?.data?.privacy?.description);
  //render header
  const renderHeaderBar = () => {
    return (
      <div>
        <HeaderScreen count={wishListCount} />
      </div>
    );
  };
  /**
   *
   * @returns render main screen
   */

  const renderPrivacy = () => {
    return (
      <div className="container">
        <div className="privacyPolicy  flt">{t("privacy")}</div>
        <div>
          {/* {privacyData && privacyData?.data?.data?.privacy?.description &&
                        <p
                            dangerouslySetInnerHTML={{
                                __html: privacyData?.data?.data?.privacy?.description,
                            }}
                        />
                    } */}
          <div className="privacy_sub flt"> Privacy Statement</div>
          <ul className="privacy_list">
            <li>
              Your Privacy Matters: At Prakash Jewellers, we deeply respect your
              privacy and value the trust you place in us. This privacy
              statement outlines how we gather, store, and use information
              collected through our website and Prakash Jewellers Store. By
              accessing and using our platforms, you agree to abide by the terms
              of this statement.
            </li>
            <li>
              <div className="privacy_sub flt">
                {" "}
                Collection of Personal Data:
              </div>
              <ul className="privacy_sub_list">
                <li>
                  {" "}
                  Information You Provide: We receive and store information you
                  submit on our website or in our store. This includes
                  personally identifiable details such as your name, gender,
                  email address, mobile number, age, and, in certain cases,
                  billing information. Not providing requested data may impact
                  our ability to serve you effectively.
                </li>
                <li>
                  Automated Information: We utilize "cookies" to collect
                  non-identifiable data. Cookies are small data files stored on
                  your device. You can control cookie preferences in your
                  browser settings, though it may affect certain website
                  features.
                </li>
                <li>
                  {" "}
                  Information from Other Sources: Some areas of our premises are
                  under CCTV surveillance for security reasons. We may also
                  store images, photographs, and videos taken at our in-store or
                  online events.
                </li>
              </ul>
            </li>
            <li>
              <div className="privacy_sub flt"> Usage of Personal Data:</div>
              <ul className="privacy_sub_list">
                <li>
                  To enhance your website experience and provide tailored
                  services.
                </li>
                <li>To serve you effectively based on your preferences.</li>
                <li>To gauge consumer interest and improve our products.</li>
                <li>
                  To safeguard against errors, fraud, and criminal activity.
                </li>
                <li>To administer contests, promotions, and surveys.</li>
                <li>To send communications related to orders and products.</li>
                <li>
                  {" "}
                  To troubleshoot issues, resolve disputes, and process
                  payments.
                </li>
                <li>To comply with terms and conditions.</li>{" "}
              </ul>
            </li>
            <li>
              <div className="privacy_sub flt"> Sharing of Personal Data:</div>
              <ul className="privacy_sub_list">
                <li> With third-party vendors for service provision only.</li>
                <li>
                  {" "}
                  With law enforcement and government entities as required by
                  law.
                </li>
                <li>
                  {" "}
                  In business transactions, provided recipient commits to
                  privacy standards consistent with this policy.
                </li>{" "}
              </ul>
            </li>
            <li>
              <div className="privacy_sub flt"> Personal Data Protection:</div>
              <ul className="privacy_sub_list">
                <li> We employ industry-standard security measures.</li>
                <li>
                  We encrypt transmitted data using Secure Sockets Layer (SSL)
                  software.
                </li>
                <li>
                  We have physical, technical, and administrative safeguards.
                </li>
                <li> Only authorized personnel access data.</li>
                <li>
                  While loss or misuse is not entirely preventable, we make
                  reasonable efforts to prevent it.
                </li>{" "}
              </ul>
            </li>
            <li>
              {" "}
              <div className="privacy_sub flt"> Access and Retention:</div>
              <ul className="privacy_sub_list">
                <li>
                  You have rights to access, correct, and delete your data.
                </li>
                <li>You can opt out of communications at any time.</li>
                <li> We keep data for legal and regulatory compliance.</li>
              </ul>
            </li>
            <li>
              {" "}
              <div className="privacy_sub flt"> Privacy Policy Changes:</div>
              <ul className="privacy_sub_list">
                <li>
                  {" "}
                  We may modify our policy and recommend checking periodically.
                </li>
                <li>Continued use post-modifications implies acceptance.</li>
              </ul>
            </li>
            <li>
              {" "}
              <div className="privacy_sub flt"> Contact Information:</div>
              <ul className="privacy_sub_list">
                <li>
                  Prakash Jewellers, Gangamma Towers, Main Road, Karkala, Udupi
                  District - 574104 Phone: 8762117314 Email:
                  prakashjewellerskarkala@gmail.com Your privacy is of utmost
                  importance. If you have inquiries about this Privacy
                  Statement, feel free to contact us at the provided details.
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  //loading image display
  // const loadingDisplay = () => {
  //     return (
  //         <div className="product_loader flt">
  //             <img src={loader} alt="populargold" />
  //         </div>
  //     );
  // };

  //render footer
  const renderFooter = () => {
    return (
      <div className="main_login flt">
        <FooterScreen />
      </div>
    );
  };
  return (
    <>
      {renderHeaderBar()}
      {loaderdata !== "isfull"}
      <div className="privacy flt">{renderPrivacy()}</div>
      {renderFooter()}
    </>
  );
};
export default withTranslation()(PrivacyPolicy);
