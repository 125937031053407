import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { alertActions } from "../../../actions/alert.actions";
import { useDispatch } from "react-redux";

const Search = (props) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [searchResponse, setSearchResponse] = useState();
  const onhandleChange = (e) => {
    setSearch(e.target.value.trimStart());
  };
  const submitSearch = () => {
    if (search !== "") {
      navigate(`/product-list/${search.trim()}`);
      document.body.classList.remove("modal-open");
      props.closeSearch();
    } else {
      dispatch(alertActions.error("Please type product name..!"));
    }
  };

  return (
    /* <!-- Search Modal --> */
    <div className="modalPop" id="searchModal">
      <div className="modal-dialog">
        <div className="modal-content">
          {/* <!-- Modal body --> */}
          <div className="modal-body">
            <span>
              <input
                type="text"
                placeholder="Search for items"
                name="serach"
                onChange={onhandleChange}
                value={search}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    submitSearch();
                  }
                }}
              />
            </span>
          </div>

          {/* <!-- Modal footer --> */}
          <div className="modal-footer">
            <button className="search_btn" onClick={submitSearch}>
              Search
            </button>
            <button className="close_btn" onClick={props.closeSearch}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Search;
