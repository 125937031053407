import FooterScreen from "../../_common/FooterScreen/FooterScreen";
import HeaderScreen from "../../_common/Header/HeaderScreen";
import "./PlanOfPurchase.css";
import usePlanOfPurchase from "./usePlanOfPurchase";
import { withTranslation } from "react-i18next";
import { loader } from "../../../assets/images";
import useCountHook from "../../hooks/useCountHook";

const PlanOfPurchase = (props: any) => {
  const { wishListCount } = useCountHook();
  const { t } = props;
  const { planOfPurchase, loaderdata } = usePlanOfPurchase();

  //render header
  const renderHeaderBar = () => {
    return (
      <div>
        <HeaderScreen count={wishListCount} />
      </div>
    );
  };
  /**
   *
   * @returns render main screen
   */

  const renderPrivacy = () => {
    return (
      <div className="container">
        <div className="privacyPolicy flt">
          Introducing “Akshaya” Our Exclusive Gold Purchase Plan
        </div>
        <div>
          {/* {privacyData && privacyData?.data?.data?.privacy?.description &&
                        <p
                            dangerouslySetInnerHTML={{
                                __html: privacyData?.data?.data?.privacy?.description,
                            }}
                        />
                    } */}

          <p>
            At Prakash Jewellers, we understand the value of your hard-earned
            money and your desire to invest in timeless treasures. That's why
            we're thrilled to present our unique Gold Purchase Plan, tailored to
            provide you with an extraordinary opportunity to own exquisite gold
            ornaments while making your investment journey even more rewarding.
          </p>
          <div className="privacy_sub flt"> How It Works:</div>
          <p>
            {" "}
            Our Gold Purchase Plan is designed to offer you a seamless and
            convenient way to accumulate gold ornaments over time. Here's how it
            works:
          </p>

          <p>
            Select Your Plan: Choose the plan that best suits your budget and
            aspirations. We offer four convenient plans: ₹500, ₹1000, ₹2000, and
            ₹5000.
          </p>

          <p>
            {" "}
            Monthly Contributions: Contribute your chosen plan amount every
            month for 11 months. For instance, if you opt for the ₹500 plan,
            you'll contribute ₹500 every month for 11 months.
          </p>

          <p>
            The 12th Month Bonus: We offer you the 12th month's contribution as
            a bonus. This means that if you've contributed ₹500 per month,
            you'll receive an additional ₹500 in the 12th month.
          </p>

          <p>
            {" "}
            Accumulate Your Gold Fund: Over the course of 12 months, your
            contributions and bonus will accumulate to create a significant gold
            fund. Your total amount at the end of 12 months will be ₹6000 (₹500
            x 11 + ₹500 bonus).
          </p>

          <p>
            Exclusively for Gold Ornaments: Your gold fund of ₹6000 can be
            redeemed only for purchasing gold ornaments from our stunning
            collection. Choose from a wide array of intricately designed gold
            jewellery that suits your taste and style.
          </p>

          <p>
            {" "}
            No Gold Coins, Silver, or Diamond Ornaments: Please note that this
            plan is exclusively for the purchase of gold ornaments. It cannot be
            used to buy gold coins, silver items, or diamond jewellery.
          </p>
          <div className="privacy_sub flt">
            {" "}
            Why Choose Our Gold Purchase Plan:
          </div>

          <p>
            Flexibility: Our range of plans allows you to choose the
            contribution that aligns with your budget and financial goals.
          </p>
          <p>
            {" "}
            Bonus Appreciation: Enjoy the satisfaction of receiving the 12th
            month's contribution as a bonus, enhancing your purchasing power.
          </p>

          <p>
            {" "}
            Stunning Collection: Explore our curated collection of gold
            ornaments, crafted with exquisite artistry, and find pieces that
            resonate with your personality.
          </p>

          <p>
            {" "}
            Secure Investment: Investing in gold is a time-tested way to secure
            your financial future, and our plan helps you do so conveniently.
          </p>
          <p>
            {" "}
            Ease of Redemption: Redeeming your gold fund is hassle-free. Simply
            select the jewellery you desire, contact us and your fund will cover
            the cost.
          </p>

          <p>
            {" "}
            Invest in your dreams, indulge in luxury, and create cherished
            memories with our Gold Purchase Plan. Join us on this rewarding
            journey of acquiring timeless treasures.
          </p>
          <p>
            To get started or learn more, contact our customer service team at
            prakashjewellerskarkala@gmail.com or call us at 8762117314(Mon -
            Sat: 9.30AM to 7.15PM)
          </p>

          <div className="privacy_sub flt">
            {" "}
            Embrace the allure of gold with Prakash Jewellers
          </div>
          <p>
            Terms and conditions apply. The Gold Purchase Plan is subject to
            availability and may be subject to change. Please contact us for the
            most up-to-date information.
          </p>
        </div>
      </div>
    );
  };

  //loading image display
  // const loadingDisplay = () => {
  //     return (
  //         <div className="product_loader flt">
  //             <img src={loader} alt="populargold" />
  //         </div>
  //     );
  // };

  //render footer
  const renderFooter = () => {
    return (
      <div className="main_login flt">
        <FooterScreen />
      </div>
    );
  };
  return (
    <>
      {renderHeaderBar()}
      {loaderdata !== "isfull"}
      <div className="privacy flt">{renderPrivacy()}</div>
      {renderFooter()}
    </>
  );
};
export default withTranslation()(PlanOfPurchase);
