import * as axios from "../network/Network";

const config = require("../network/api.json");

class ProductService {
    static searchData(search, main, sub, page) {
        var bodyFormData = new FormData();
        bodyFormData.append("search", search.length > 0 ? search : "");
        bodyFormData.append("main", main.length > 0 ? JSON.stringify(main) : "");
        bodyFormData.append("sub", sub.length > 0 ? JSON.stringify(sub) : "");
        const result = axios.appRequest({
            method: axios.POST,
            url: config.user.searchValue + `?page=${page}`,
            data: bodyFormData,
        });
        return Promise.resolve(result);
    }
    //productDetails page
    static getSingleProduct(data) {
        const result = axios.appRequest({
            method: axios.GET,
            url: config.user.product_jewellary + `/${data}`,
        });
        return Promise.resolve(result);
    }
    //add to cart addToCart
    static addToCart(product_id) {
        return axios.appRequest({
            method: axios.POST,
            url: config.user.addToCart,
            data: { product_id },
        });
    }
    //get cart
    static getCart() {
        const result = axios.appRequest({
            method: axios.GET,
            url: config.user.getCart,
        });
        return Promise.resolve(result);
    }

    //get all state
    static getState() {
        const result = axios.appRequest({
            method: axios.GET,
            url: config.user.getState,
        });
        return Promise.resolve(result);
    }
    //get district
    static getDistrict(id) {
        const result = axios.appRequest({
            method: axios.GET,
            url: config.user.getDistrict + `/${id}`,
        });
        return Promise.resolve(result);
    }
    
    static getProdDetails(data, current) {
        const result = axios.appRequest({
          method: axios.POST,
          url:
            config.user.getProdDetails + `?product_id=${data}&user_id=${current}`,
        });
        return Promise.resolve(result);
      }
    
    static checkout(
        name,
        mobile,
        city,
        pincode,
        address,
        state,
        product_id,
        res_total,
        gst_amount,
        distrct
    ) {
        var bodyFormData = new FormData();
        bodyFormData.append("name", name);
        bodyFormData.append("mobile", mobile);
        bodyFormData.append("city", city);
        bodyFormData.append("pincode", pincode);
        bodyFormData.append("address", address);
        bodyFormData.append("state", state);
        bodyFormData.append("product_id", product_id);
        bodyFormData.append("res_total", res_total);
        bodyFormData.append("gst_amount", gst_amount);
        bodyFormData.append("district", distrct);
        const result = axios.appRequest({
            method: axios.POST,
            url: config.user.checkout,
            data: bodyFormData,

        });
        return Promise.resolve(result);
    }

    static payment_success(order_id) {
        const result = axios.appRequest({
            method: axios.POST,
            url: config.user.payment_success + `/${order_id}`,
        });
        return Promise.resolve(result);
    }
    static payment_failure(order_id) {
        const result = axios.appRequest({
            method: axios.POST,
            url: config.user.payment_failure + `/${order_id}`,
        });
        return Promise.resolve(result);
    }

    // ongoing order
    static ongoinOrder() {
        const result = axios.appRequest({
            method: axios.GET,
            url: config.user.ongoing,
        });
        return Promise.resolve(result);
    }

    //my-details
    static myDetails(
        first_name,
        email,
        address,
        mobile_no,
        c_password,
        new_password,
        user_id
    ) {
        var bodyFormData = new FormData();
        bodyFormData.append("first_name", first_name);
        bodyFormData.append("email", email);
        bodyFormData.append("mobile_no", mobile_no ? mobile_no : "");
        bodyFormData.append("current_password", c_password ? c_password : "");
        bodyFormData.append("change_password", new_password ? new_password : "");
        bodyFormData.append("address", address);
        bodyFormData.append("billing_address", address);

        return axios.appRequest({
            method: axios.POST,
            url: config.user.mydetails + `/${user_id}`,
            data: bodyFormData,
        });
    }

    static privacyPolicy() {
        const result = axios.appRequest({
            method: axios.GET,
            url: config.user.privacy,
        });
        return Promise.resolve(result);
    }
    static planOfPurchase() {
        const result = axios.appRequest({
            method: axios.GET,
            url: config.user.privacy,
        });
        return Promise.resolve(result);
    }
}
export { ProductService };
