import { useEffect, useState } from "react";
import { ProductService } from "../../../services";
const usePlanOfPurchase = () => {
    const [planOfPurchase, setPlanOfPurchase] = useState<any>("");
    const [loaderdata, setLoaderdata] = useState("notset");
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    /**
     * privacy policy api call
     */
    useEffect(() => {
        ProductService.planOfPurchase().then((response) => {
            setLoaderdata("isfull");
            setPlanOfPurchase(response)
        })
    }, [])
    return {
        planOfPurchase,
        loaderdata
    }
}
export default usePlanOfPurchase;