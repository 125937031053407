import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { alertActions } from "../../../actions";
import { ContactService } from "../../../services";

const useContact = () => {
    const dispatch = useDispatch();
    const [submitted, setSubmitted] = useState(false);
    const [add, setAdd] = useState({
        name: "",
        mobile_no: "",
        email: "",
        reason: "",
    });
    const navigate = useNavigate();
    
    //handle change even
    function changeHandler(e: any) {
        const { name, value } = e.target;
        setAdd((add) => ({ ...add, [name]: value }));
    }
    //on submit
    function handleSubmit(e: any) {
        e.preventDefault();
        setSubmitted(true);
        var filter = /^[0-9]{10}$/;
        var pattern = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/; // eslint-disable-line

        if (
            add.name &&
            add.mobile_no &&
            add.email &&
            add.reason
        ) {
            if (add && add.email && !pattern.test(add.email)) {
                dispatch(alertActions.error("Enter Valid Email Address"));
            } else if (add && add.mobile_no && !filter.test(add.mobile_no)) {
                dispatch(alertActions.error("Enter Valid Mobile Number"));
            } else {

                ContactService.add(add)
                    .then((data) => {
                        if (data.data.status.code === 0) {
                            setAdd({
                                name: "",
                                mobile_no: "",
                                email: "",
                                reason: "",
                            });
                            // history.push("/");
                            setSubmitted(false);
                            dispatch(alertActions.success(data.data.status.message));

                        }
                        //  window.location.reload();
                    })
                    .catch((error) => {
                        console.log("Errorreg", error);
                        //   setLoading(false);
                    });
            }
        }
    }
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    /**
* go to home
*/
    const gotoHome = () => {
        navigate("/");
    }
    return {
        submitted,
        add,
        changeHandler,
        handleSubmit,
        gotoHome
    }
}
export default useContact;