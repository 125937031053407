import "./Menu.css";
import React from "react";
import { logo } from "../../../assets/images";
import { menu1 } from "../../../assets/images";
import { menu2 } from "../../../assets/images";
import { menu3 } from "../../../assets/images";
import { menu4 } from "../../../assets/images";
import { search } from "../../../assets/images";
import { Link } from "react-router-dom";
import { whish } from "../../../assets/images";
import useMenu from "./useMenu";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import PlanOfPurchase from "../../pages/planofpurchase/PlanOfPurchase";
import Search from "../Search/Search";
const Menu = (props: any) => {
  const { t, count } = props;
  console.log(count, "kkkkkkk");
  /**
   * use menu hook
   */
  const {
    login_screen,
    handleLogout,
    openSearch,
    closeSearch,
    user_id,
    menudata,
    cartCount,
    myAccount,
    myOrder,
    getMenuId,
    gotoHome,
    moveWishlist,
    moveCart,
    aboutUs,
    showSearchBar,
    home,
    planofpurchase,
    loader,
    contactUs,
  } = useMenu();

  const [toggleBar, setToggleBar] = useState(false);
  const [showNav, setShowNav] = useState(false);

  const [showDailyRate, setShowDailyRate] = useState(false);
  const displayModal = () => {
    setShowDailyRate(true);
  };

  /**
   * get navigation menu
   */
  const openNav = () => {
    setShowNav(!showNav);
    setToggleBar(!toggleBar);
  };
  /**
   *
   * @returns render menu main screen
   */
  const renderMenu = () => {
    return (
      <>
        <div className="menu_main flt">
          <div className="container">
            <div className="header_main  flt">
              <button
                className={
                  "toggle_bar hide_desktop" + (showNav ? " toggle_rotate" : "")
                }
                onClick={openNav}
              >
                <span></span>
                <span></span>
                <span></span>
              </button>
              <ul
                className={
                  cartCount > 0
                    ? "cart_section_1 cart_section1 "
                    : "cart_section_1 "
                }
              >
                <li>
                  <i>
                    <img src={menu1} alt="" />
                  </i>
                  <ul className="submenu dailyrate">
                    <li>
                      <span>Gold 18KT</span>
                      <span>
                        {" "}
                        {t("rs")} {menudata?.daily_rates?.[0]?.gold18}
                      </span>
                    </li>
                    <li>
                      <span>Gold 22KT </span>
                      <span>
                        {t("rs")} {menudata?.daily_rates?.[0]?.gold22}
                      </span>
                    </li>
                    <li>
                      <span>Silver</span>
                      <span>
                        {" "}
                        {t("rs")} {menudata?.daily_rates?.[0]?.silver}
                      </span>
                    </li>
                  </ul>
                  <br></br>
                  <span className="hide_mobile">Daily Rates</span>
                </li>
                <li>
                  {!user_id ? (
                    <i onClick={login_screen}>
                      <img src={menu2} alt="" />
                    </i>
                  ) : (
                    <i>
                      <img src={menu2} alt="" />
                    </i>
                  )}
                  <ul className="submenu">
                    <li onClick={myAccount}>{t("my_account")}</li>
                    <li onClick={myOrder}>{t("my_order")}</li>
                    {user_id && <li onClick={handleLogout}>{t("logout")}</li>}
                  </ul>
                  <br></br>
                  <span className="hide_mobile">Profile</span>
                </li>
                <li>
                  <img onClick={openSearch} src={search} alt="" />
                  <br />
                  <span className="hide_mobile">Search</span>
                </li>
                {/* <li>
                                    {!user_id ?
                                        <i onClick={login_screen}><img src={menu2} alt="" /></i> :
                                        <i ><img src={menu2} alt="" /></i>
                                    }
                                    <ul classNameName="submenu">
                                        <li onClick={myAccount}>{t("my_account")}</li>
                                        <li onClick={myOrder}>{t("my_order")}</li>
                                        {user_id &&
                                            <li onClick={handleLogout}>{t("logout")}</li>
                                        }
                                    </ul>
                                </li> */}
                {/* <li>
                                    <b>{cartCount > 0 ? cartCount : ""}</b>
                                    <i onClick={moveCart} ><img src={menu3} alt="" /></i>

                                </li> */}
              </ul>
              <div className="logo" onClick={gotoHome}>
                <img src={logo} alt="no_image" />
              </div>
              <div className="filler hide_desktop"></div>
              <ul
                className={
                  cartCount > 0 ? "cart_section cart_section1" : "cart_section "
                }
              >
                <li className="web_action_btn web_action_wish">
                  <i>
                    {" "}
                    <img src={whish} alt="" onClick={moveWishlist} />
                  </i>

                  <br />
                  {count > 0 ? (
                    <span onClick={moveWishlist}>
                      <a className="hide_mobile">Wishlist </a> ({count})
                    </span>
                  ) : (
                    <span className="hide_mobile" onClick={moveWishlist}>
                      Wishlist
                    </span>
                  )}
                </li>

                <li className="hide_mobile web_action_btn">
                  {/* <b>{cartCount > 0 ? cartCount : ""}</b> */}
                  <i onClick={planofpurchase}>
                    <img src={menu4} alt="" />
                  </i>
                  <br></br>
                  <span>Plan Of Purchase</span>
                </li>
                <li className="hide_mobile web_action_btn">
                  <b onClick={moveCart}>{cartCount > 0 ? cartCount : ""}</b>
                  <i onClick={moveCart}>
                    <img src={menu3} alt="" />
                  </i>
                  <br></br>
                  <span onClick={moveCart}>Cart</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="header_menu_list flt">
            <ul className={"menuH" + (showNav ? " fixed_menu" : "")}>
              <li onClick={home}>{t("homee")} </li>
              {menudata &&
                menudata?.category &&
                menudata?.category.map((data: any) => (
                  <li
                    onClick={() => {
                      getMenuId(data?.title);
                      openNav();
                    }}
                  >
                    {data?.title}
                  </li>
                ))}
              <li onClick={aboutUs}>{t("about_us")}</li>
              <li onClick={contactUs}>{t("contact_us")}</li>
              <li>
                <div className="sidemenu_list hide_desktop">
                  <ul>
                    <li>
                      <i onClick={planofpurchase}>
                        <img src={menu4} alt="" />
                      </i>
                    </li>
                    <li>
                      {" "}
                      <i onClick={moveCart}>
                        <img src={menu3} alt="" />
                      </i>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  };
  const renderSearch = () => {
    return <Search closeSearch={() => closeSearch()} />;
  };
  return (
    <>
      {renderMenu()}
      {showSearchBar && renderSearch()}
    </>
  );
};
export default withTranslation()(Menu);
