import FooterScreen from "../../_common/FooterScreen/FooterScreen";
import HeaderScreen from "../../_common/Header/HeaderScreen";
import useMyAccount from "./useMyAccount";
import "./MyAccount.css";
import { withTranslation } from "react-i18next";
import useCountHook from "../../hooks/useCountHook";

const MyAccount = (props: any) => {
  const { wishListCount } = useCountHook();
  const { t } = props;
  const {
    name,
    mobile_no,
    email,
    newpassword,
    current_password,
    submitted,
    address,
    onSubmit,
    gotoHome,
    changeHandler,
  } = useMyAccount();
  /**
   * render header
   */
  const renderHeaderBar = () => {
    return (
      <div>
        <HeaderScreen count={wishListCount} />
      </div>
    );
  };
  /**
   *
   * @returns render main my account screen
   */
  const renderMyAccount = () => {
    return (
      <div className="myaccount flt">
        <div className="container">
          <div className="breadcrumbs breadcrumbs1  flt">
            <span onClick={gotoHome}>{t("home")}</span>
            <span>{t("myaccount")}</span>
          </div>
          <div className="cartData flt">{t("myaccount")}</div>
          <div className="row">
            <div className="col-sm-4">
              <div className="mob_number mycontact flt">
                <label>{t("name")}</label>
                <input
                  name="name"
                  value={name}
                  onChange={changeHandler}
                  placeholder={t("name_placeholder")}
                  className={submitted && name ? " is-invalid" : ""}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="mob_number mycontact mob flt">
                <label>{t("email")}</label>
                <input
                  name="email"
                  value={email}
                  // onChange={changeHandler}
                  className={submitted && email ? " is-invalid" : ""}
                  readOnly
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="mob_number mob mycontact flt">
                <label>{t("Mobile number")}</label>
                <input
                  type="number"
                  name="mobile_no"
                  value={mobile_no}
                  // onChange={changeHandler}

                  className={submitted && mobile_no ? " is-invalid" : ""}
                  readOnly
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="mob_number mycontact flt">
                <label>{t("new_password")}</label>
                <input
                  name="new_password"
                  value={newpassword}
                  onChange={changeHandler}
                  placeholder={t("password_placeholder")}
                  className={submitted && newpassword ? " is-invalid" : ""}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="mob_number mycontact flt">
                <label>{t("c_password")}</label>
                <input
                  name="current_password"
                  value={current_password}
                  placeholder={t("password_placeholder")}
                  onChange={changeHandler}
                  className={submitted && current_password ? " is-invalid" : ""}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="mob_number mycontact flt">
                <label>Address</label>
                <input
                  name="address"
                  value={address}
                  onChange={changeHandler}
                  className={submitted && address ? " is-invalid" : ""}
                  placeholder={t("address")}
                />
              </div>
            </div>
            <div className="submit_btn flt">
              <button onClick={onSubmit}>{t("save_continue")}</button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  /**
   * render footer
   */
  //render footer
  const renderFooter = () => {
    return (
      <div className="main_login flt">
        <FooterScreen />
      </div>
    );
  };
  return (
    <div className="flt">
      {renderHeaderBar()}
      {renderMyAccount()}
      {renderFooter()}
    </div>
  );
};
export default withTranslation()(MyAccount);
