import { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { alertActions } from "../../../actions";
import { ProductService } from "../../../services";
import useCountHook from "../../hooks/useCountHook";

const useProductDetails = () => {
  const location = useLocation();
  const [sku, setSku] = useState();
  let history = useNavigate();
  const { wishListCount, getWishListCount } = useCountHook();

  var sku1 = window.location.href.split("/")[4];
  const [productDetails, setProductDetails] = useState("");
  const image_url = process.env.REACT_APP_MEDIA_DOMAIN;
  const navigate = useNavigate();
  const userData = useSelector((state) => state?.userData);
  const usr_id = useSelector((state) => state?.userData?.user?._id);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const isUser = userData ? Object.keys(userData).length : 0;
  const [loaderdata, setLoaderdata] = useState("notset");
  const [showOtp, setShowOtp] = useState(false);
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();
  /**
   * set sku
   */
  useEffect(() => {
    setSku(location?.state?.sku);
  }, [location]);
  //for scroll horizontalli likely images
  useEffect(() => {
    window.scrollTo(0, 0);
    const slider = document.querySelector(".items");
    let isDown = false;
    let startX;
    let scrollLeft;
    slider.addEventListener("mousedown", (e) => {
      isDown = true;
      slider.classList.add("active");
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener("mouseleave", () => {
      isDown = false;
      slider.classList.remove("active");
    });
    slider.addEventListener("mouseup", () => {
      isDown = false;
      slider.classList.remove("active");
    });
    slider.addEventListener("mousemove", (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
    });
    // eslint-disable-next-line
  }, []);
  /**
   * get product details thriugh sku
   */
  useEffect(() => {
    ProductService.getSingleProduct(sku ? sku : sku1)
      .then((response) => {
        if (response?.data?.status?.code === 0) {
          setProductDetails(response?.data?.data);
          //setsingleProductList(singleproductList?.data?.data);
          setLoaderdata("isfull");
        } else {
          setLoaderdata("isempty");
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, [sku]);

  //based on sku of likely image change image in the main screen
  const changeImage = (display) => {
    window.scrollTo(0, 0);
    navigate(`/product-details/${display?.SKU}`, {
      state: { sku: display.SKU },
    });
  };
  /**
   * add to cart major functionality
   */
  const addCart = (data) => {
    if (isUser === 0) {
      // dispatch(alertActions.error(message?.login?.login));
      navigate("/Login");
    } else {
      ProductService.addToCart(data?.pro_details?.product_id).then(
        (response) => {
          if (response?.data?.status?.code === 0) {
            dispatch(alertActions.success(response?.data?.status?.message));
            navigate("/cart");
          }
        }
      );
    }
  };
  //close otp box on state change
  const openOtp = () => {
    document.body.classList.add("modal-open");
    setShowOtp(true);
  };

  //close otp box on state change
  const closeOtp = () => {
    document.body.classList.remove("modal-open");
    setShowOtp(false);
  };

  // const wishListCount = () => {
  //   if (usr_id) {
  //     OrderService.getWishlist(usr_id)
  //       .then((order) => {
  //         setCount(order?.data?.data?.total);

  //         return true;
  //       })
  //       .catch((error) => {
  //         console.log("Errorreg", error);
  //         return false;
  //       });
  //   }
  // };

  const changeLargeImage = (index) => {
    console.log("jhfdskjfbh", index, productDetails.pro_images[index].image);
    setSelectedImageIndex(index);
  };

  const imgChange = (product) => {
    if (usr_id) {
      ProductService.getProdDetails(product.pro_details.product_id, usr_id)
        .then((prodList) => {
          ProductService.getSingleProduct(sku ? sku : sku1)
            .then((response) => {
              if (response?.data?.status?.code === 0) {
                getWishListCount();
                setProductDetails(response?.data?.data);
                setLoaderdata("isfull");
              } else {
                setLoaderdata("isempty");
              }
            })
            .catch((err) => console.log(err));
          if (prodList.status.code === 4) {
            dispatch(alertActions.error(prodList.status.message));
          }
        })
        .catch((error) => {
          console.log("Errorreg", error);
          dispatch(alertActions.error("Please login....!"));
          history("/login");
        });
    } else {
      history("/login");
    }
  };
  return {
    productDetails,
    image_url,
    loaderdata,
    showOtp,
    count,
    wishListCount,
    selectedImageIndex,
    getWishListCount,
    changeImage,
    addCart,
    closeOtp,
    openOtp,
    imgChange,
    changeLargeImage,
  };
};
export default useProductDetails;
