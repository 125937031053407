import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { ProductService } from "../../../services";
import useCountHook from "../../hooks/useCountHook";

const useCart = () => {
  const [cartData, setCartData] = useState<any>([]);
  const user: any = JSON.parse(localStorage.getItem("state") || "{}");
  const isUser = user ? Object.keys(user.userData).length : 0;
  const image_url = process.env.REACT_APP_MEDIA_DOMAIN;
  const navigate = useNavigate();
  const { wishListCount, getWishListCount } = useCountHook();
  const [loaderdata, setLoaderdata] = useState("notset");
  useEffect(() => {
    if (isUser) {
      ProductService.getCart().then((response) => {
        if (response?.data?.status?.code === 0) {
          setCartData(response?.data?.data?.cart);
          setLoaderdata("isfull");
          getWishListCount();
        } else {
          setLoaderdata("isempty");
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  //move to checkout
  const Checkout = () => {
    navigate(`/checkout/${cartData?.product?.pro_details?.product_id}`, {
      state: {
        qty: cartData?.quantity,
        total_amount: cartData?.product?.c_gross_value,
        gstAmount: cartData?.product?.c_gst_amount,
        product_id: cartData?.product?.pro_details?.product_id,
      },
    });
  };

  /**
   * go to home
   */
  const gotoHome = () => {
    navigate("/");
  };
  return {
    cartData,
    image_url,
    loaderdata,
    wishListCount,
    Checkout,
    gotoHome,
  };
};
export default useCart;
